<template>
  <header class="relative bg-white border-b border-gray-200 text-sm font-medium text-gray-700">
    <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div class="relative flex justify-around">
        <a href="#" class="top-1/2 mt-2">
          <span class="sr-only">{{ tenantTheme.brandName }}</span>
          <img
            :src="tenantTheme.headerLogoImageUrl"
            :alt="tenantTheme.headerLogoImageAlt || tenantTheme.brandName || 'Buy Tickets'"
            class="h-10 w-auto"
          >
        </a>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">

const tenantTheme = useTenantTheme()

</script>
