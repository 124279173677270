<template>
  <div class="bg-gray-100">
    <LayoutHeaderTenantLogoBasic />

    <div>
      <slot />
    </div>

    <LayoutFooterShopDynamicHorizontal />
    <UNotifications />
  </div>
</template>

<script setup lang="ts">

// const tenantTheme = useTenantTheme()

// Set some SEO-friendly head tags
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - WeTix` : 'WeTix - Buy Tickets'
  },
  meta: [
    { name: 'description', content: 'Buy tickets for your favourite events online via the official WeTix online shop.' }
  ],
  bodyAttrs: {
    class: 'h-full'
  },
  htmlAttrs: {
    class: 'h-full'
  },
  // Add tinybird analytics
  script: [
    {
      defer: true,
      src: 'https://unpkg.com/@tinybirdco/flock.js',
      'data-host': 'https://api.tinybird.co',
      'data-token': 'p.eyJ1IjogIjg5MjlkMGEzLWU1MWMtNGViNS1hNzRiLTc2ZTFmYmFkNGYwYSIsICJpZCI6ICIwMDc0ZGFjMC04YzQ4LTRiNmYtYWM1Zi0yYWVjNWNkMTFjNjgifQ.tWFZ8X6NiW9L6uonYp62eEyeuUS3CgN_sKvJGIwOCik'
    }
  ]
})

</script>
